<template>
  <v-card elevation="1" class="mt-3">
    <h3 class="pa-4">{{ $t('Order history') }}</h3>
    <v-timeline
        align-top
        dense
        class="py-0 pr-4"
    >
      <v-timeline-item color="secondary" small>

        <div>
          <v-row>
            <v-col cols="5" class="font-weight-normal">
              <strong> {{ $t('Status') }}: </strong>
            </v-col>
            <v-col cols="7">
              <v-skeleton-loader type="text" class="pa-2"></v-skeleton-loader>
            </v-col>
          </v-row>

              <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </div>
      </v-timeline-item>
    </v-timeline>
  </v-card>
</template>

<script>
export default {
  name: "OrderHistorySkeleton"
}
</script>
