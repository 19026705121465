<template>
  <v-card class="mb-6" elevation="1">
    <v-row>
      <v-col cols="12" sm="3">
        <v-skeleton-loader
            type="image"
            class="ml-3 mr-10"
        ></v-skeleton-loader>
      </v-col>

      <v-col cols="12" sm="7">
        <v-list>
          <v-list-item>
            <v-list-item-title><v-skeleton-loader type="heading"></v-skeleton-loader></v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title><b>{{ $t('Product ID:') }}</b></v-list-item-title>
            <v-list-item-subtitle><v-skeleton-loader type="text"></v-skeleton-loader></v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title><b>{{ $t('ASIN:') }}</b></v-list-item-title>
              <v-list-item-subtitle><v-skeleton-loader type="text"></v-skeleton-loader></v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title><b>{{ $t('Source:') }}</b></v-list-item-title>
            <v-list-item-subtitle><v-skeleton-loader type="text"></v-skeleton-loader></v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-col>

      <v-col cols="12" sm="2">
        <div v-if="$route.name == 'admin-product' || $route.name == 'seller-product'">
          <v-skeleton-loader type="heading" v-for="n in 3" :key="n" class="pa-4"></v-skeleton-loader>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
}
</script>
<style lang="scss" scoped>
.v-list-item {
  min-height: 35px;
}

</style>