<template>
  <div>
    <account-role-title role="Buyer" />
    <span class="deals-details-title">{{ $t("Product Details") }}</span>
    <v-row>
      <v-col md="9">
        <product-data-skeleton v-if="orderLoading"></product-data-skeleton>
        <product-data v-else></product-data>
        <product-details-skeleton v-if="orderLoading"></product-details-skeleton>
        <product-statistics v-else></product-statistics>
      </v-col>
      <v-col md="3" class="pl-0 pt-0">
        <order-details-skeleton v-if="orderLoading"></order-details-skeleton>
        <order-details v-else></order-details>
        <order-history-skeleton v-if="orderLoading"></order-history-skeleton>
        <order-history v-else></order-history>
        <v-dialog
            v-if="orderData && orderData.can_be_refunded"
            v-model="refundDialog"
            persistent
            max-width="600px"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="mt-2 float-right">
              <v-btn id="refund-button" v-bind="attrs" v-on="on" color="error" class="text-uppercase mt-3">
                {{ $t('Request refund') }}
              </v-btn></br>
              <span class="deals-refund-request-period">{{ orderData.refund_period + ' days refund guarantee' }}</span>
            </div>
          </template>
          <v-card :loading="refundCardLoading" :disabled="refundCardLoading">
            <v-form ref="FormRefund" :v-model="validRefund">
              <v-card-title>
                <span class="text-h5">{{ $t('Report problem?') }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <p>{{ 'Please notify us if you have any problems with the refund and specify them. Refunds are processed within ' + orderData.refund_period + ' days. If the refund is approved, the money will be credited to your internal account' }}</p>
                  <v-row>
                    <v-col cols="12">
                      <v-radio-group v-model="refundInfo">
                        <span v-for="item in refundVariants">
                          <v-radio
                            :key="item"
                            :label="$t(item)"
                            :value="$t(item)"
                          />
                          <v-expand-transition>
                            <v-textarea
                              height="150"
                              v-show="item === refundInfo"
                              outlined
                              name="input"
                              :rules="refundRules"
                              label="Description"
                              v-model="refundNote"
                            />
                          </v-expand-transition>
                        </span>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="refundDialog = false"
                >
                  {{ $t('Close') }}
                </v-btn>
                <v-btn color="success darken-1" text
                      @click="refund(orderData.id)"
                >
                  {{ $t('Submit') }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OrderDetails from "@/views/cabinet/order/order-view/OrderDetails";
import OrderHistory from "@/views/cabinet/order/order-view/OrderHistory";
import ProductData from "@/views/cabinet/order/order-view/ProductData";
import ProductStatistics from "@/views/cabinet/order/order-view/ProductStatistics";
import store from "@/store";
import router from "@/router";
import {computed, ref, watch} from "@vue/composition-api";
import {getCurrentInstance} from "@vue/composition-api/dist/vue-composition-api";
import axios from "@axios";
import ProductDataSkeleton from "@/views/market/components/ProductDataSkeleton";
import ProductDetailsSkeleton from "@/views/market/components/ProductDetailsSkeleton";
import OrderDetailsSkeleton from "@/views/market/components/OrderDetailsSkeleton";
import OrderHistorySkeleton from "@/views/market/components/OrderHistorySkeleton";
import {useRouter} from "@core/utils";

export default {
  name: "Order",
  components: {
    OrderHistorySkeleton,
    OrderDetailsSkeleton,
    ProductDetailsSkeleton, ProductDataSkeleton, ProductStatistics, ProductData, OrderHistory, OrderDetails
  },
  setup() {
    const {route} = useRouter()
    const vm = getCurrentInstance().proxy
    const getProduct = () => store.dispatch('order/getOrder', {id: router.currentRoute.params.id})
    watch(route, () => getProduct())
    getProduct()
    const orderLoading = computed(() => store.state.order.loading)
    const orderData = computed(() => store.state.order.order)
    const refundDialog = ref(false)
    const refundNote = ref('')
    const refundRules = [v => !!v || 'Description required', v => v.length > 20 || 'Minimal length of characters is 20']
    const validRefund = ref(true)
    const FormRefund = ref(null)
    const refundCardLoading = ref(false)
    const refundVariants = ref([
      'Incorrect information', 'The product is not as described', 'The supplier\'s price is not as described',
      'No delivery to my area', 'The product is out of stock', 'Other reason'])
    const refundInfo = ref('')
    const refundFullNote = computed(() => {
      if(refundInfo.value) return refundInfo.value + '. ' + refundNote.value
      return refundNote.value
    })

    const refund = (id) => {
      if (FormRefund.value.validate()) {
        refundCardLoading.value = true
        axios.post('refund-order/' + id, {note: refundFullNote.value}).then(() => {
          refundDialog.value = false
          refundCardLoading.value = false
          getProduct()
        }).catch(err =>
            vm.$notify({
              group: 'deals',
              title: vm.$t('something went wrong'),
              text: '',
              type: 'error'
            })
        )
      }
    }
    return {
      refundInfo,
      refundVariants,
      orderLoading,
      orderData,
      refundDialog,
      refund,
      refundNote,
      refundRules,
      validRefund,
      FormRefund,
      refundCardLoading
    }
  }
}
</script>
<style>
#refund-button::before {
  background-color: transparent !important;
}
</style>