<template>
  <v-card elevation="1">
    <v-list>
      <v-list-item>
        <h3>{{ $t('Order details') }}</h3>
      </v-list-item>
      <v-list-item>
        <v-list-item-title><b>{{ $t('Product ID') }}: </b></v-list-item-title>
        <v-list-item-subtitle><v-skeleton-loader type="text"></v-skeleton-loader></v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
        <v-list-item-title><b>{{ $t('Order ID') }}: </b></v-list-item-title>
        <v-list-item-subtitle><v-skeleton-loader type="text"></v-skeleton-loader></v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
        <v-list-item-title><b>{{ $t('Order date') }}: </b></v-list-item-title>
        <v-list-item-subtitle><v-skeleton-loader type="text"></v-skeleton-loader></v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
        <v-list-item-title><b>{{ $t('Status') }}: </b></v-list-item-title>
        <v-list-item-subtitle><v-skeleton-loader type="text"></v-skeleton-loader></v-list-item-subtitle>

      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "OrderDetailsSkeleton"
}
</script>
